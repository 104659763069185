body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background: #f4f2ea;
  background: #f7f6f0;
  width: 100%;
  height: 100%;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

@media only screen and (max-width: 600px) {
  .Home {
    display: flex;
    flex-direction: column;
  }
  .left {
    background: #f7f6f0;
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: flex-start;
  }

  .right {
    background-image: url("./assets/images/Background10.png");
    background-size: 200% 100%;
    width: 100%;
    height: 100vh;
    display: flex;
    flex-direction: row;
    align-items: flex-start;
    justify-content: center;
  }

  .logo {
    font-size: 10rem;
    color: #a97a50;
    font-family: "hippie-mojo";
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
  }

  .slogan {
    font-family: "itc-souvenir";
    font-style: normal;
    font-weight: 500;
    font-size: 1.2rem;
    margin-bottom: 2rem;
    color: #a7784f;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
  }

  #background-video {
    height: 80%;
    width: 80%;
    /* both height and width were 80% before */
    position: absolute;
    object-fit: cover;
    margin: 5.3rem 0;
  }

  .sign-up {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    border: 1px solid #f8f7f4;
    border: none;
    padding: 2px 4px;
    border-radius: 20px;
    color: grey;
    width: 6rem;
    font-size: 14px;
    display: flex;
    background: #e9e5dc;
    font-weight: 300;
    height: 2rem;
    margin-top: 32px;
    width: 9.5rem;
  }

  .sign-up:hover {
    background: #d9d7d2;
    color: grey;
  }

  .suggestion-input {
    background: #fafafa;
    border: hidden;
    text-decoration: none;
    height: 1rem;
    outline: none;
    background: none;
    padding-left: 0.8rem;
    width: 12rem;
    font-family: system-ui, -apple-system, BlinkMacSystemFont, "Segoe UI",
      Roboto, Oxygen, Ubuntu, Cantarell, "Open Sans", "Helvetica Neue",
      sans-serif;
  }

  .suggestion-form {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    border: 1px solid #f8f7f4;
    border: none;
    padding: 2px 4px;
    border-radius: 20px;
    margin-top: 32px;
    background: #e9e5dc;
  }

  .suggestion-form-content {
    display: flex;
    flex-direction: row;
    align-items: center;
  }

  .icons {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: flex-end;
    color: #b7b1a7;
    font-family: "Helvetica Neue";
    margin-right: 0.2rem;
    height: 1.5rem;
  }

  .icon {
    margin-right: 0.2rem;
    height: 1.2rem;
    width: 1.2rem;
  }

  .bottom2 {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-between;
    font-family: "Helvetica Neue";
    font-size: 14pt;
    padding: 2rem;
    background: #e8dbc5;
    color: rgb(162, 122, 85);
    color: #403122;
    height: 21rem;
  }
  .rights-reserved {
    text-align: left;
    width: 100%;
    margin-top: 20px;
    margin-left: -2rem;
    font-size: 10pt;
  }

  .suggestion-form-content {
    display: flex;
    flex-direction: row;
    align-items: center;
  }
  .bottom {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: center;
    font-family: "Helvetica Neue";
    font-size: 14pt;
    padding: 2rem;
    background: #4e443c;
    font-size: 23px;
    color: rgb(162, 122, 85);
  }

  .content {
    display: flex;
    flex-direction: row;
    align-items: flex-start;
    margin-top: 0.6rem;
    margin-bottom: 0.6rem;
  }

  .title {
    font-family: "itc-souvenir";
    font-size: 20pt;
    /* font-size: 35pt; this looked pretty initial version */
    font-size: 32pt;
    color: white;
    white-space: nowrap;
    margin-right: 2rem;
    margin-top: 1.2rem;
  }

  .contact-form {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
  }

  .contact-input {
    border: none;
    background: none;
    height: 2rem;
    border: 1px solid #403122;
    margin: 1rem;
    background: #ede3d3;
    background: none;
    display: flex;
    flex-flow: row wrap;
    justify-content: flex-start;
    align-items: flex-start;
    text-align: start;
    width: 15rem;
    font-size: 13px;
  }
  .contact-input:nth-child(3) {
    height: 7rem;
    margin-top: 0.5rem;
    width: 14.5rem;
    padding-left: 0.3rem;
    font-size: 13px;
  }

  textarea {
    resize: none;
    font-family: system-ui, -apple-system, BlinkMacSystemFont, "Segoe UI",
      Roboto, Oxygen, Ubuntu, Cantarell, "Open Sans", "Helvetica Neue",
      sans-serif;
    font-size: 13px;
  }

  .submit-form-button {
    background: #f7f6f0;
    background: #403122;
    height: 2rem;
    margin-left: 1rem;
    margin-top: 0.2rem;
    width: 6rem;
    color: rgb(76, 68, 58);
    color: white;
  }

  .about-text {
    color: #e9e5dc;
    font-size: 13pt;
    line-height: 1.8;
  }
  .nav {
    display: flex;
    flex-direction: row;
    width: 100%;
    align-items: flex-start;
    justify-content: space-between;
    height: 7rem;
    margin-top: 0.3rem;
  }
  .left-footer {
    height: 7rem;
  }

  .sign-up-container {
    height: 25rem;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
  }
  .menu > button {
    font-family: "Helvetica Neue";
    color: #b7b1a7;
    background: none;
    font-size: 13px;
    width: 4.3rem;
  }
  .menu > button:nth-child(2) {
    margin-left: -15px;
  }
  .menu > button:hover {
    color: #403122;
  }

  .menu {
    width: 7rem;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    height: 1.5rem;
    margin-left: -5px;
  }
}

@media screen and (min-width: 600px) and (max-width: 1000px) {
  .Home {
    display: flex;
    flex-direction: column;
  }

  .top {
    display: flex;
    flex-direction: column;
  }

  .bottom {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: center;
    font-family: "Helvetica Neue";
    font-size: 14pt;
    padding: 2rem;
    background: #4e443c;
    font-size: 23px;
    color: rgb(162, 122, 85);
  }

  .icons {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: flex-end;
    color: #b7b1a7;
    font-family: "Helvetica Neue";
    margin-right: 0.2rem;
    height: 1.5rem;
  }

  .icon {
    margin-right: 0.2rem;
    height: 1.5rem;
    width: 1.5rem;
  }

  .menu > button {
    font-family: "Helvetica Neue";
    color: #b7b1a7;
    background: none;
    font-size: 15px;
  }

  .menu > button:hover {
    color: #403122;
  }

  .menu > button:nth-child(2) {
    margin-left: -15px;
  }

  .menu {
    margin-left: 0.2rem;
    height: 1.5rem;
    margin-left: 5px;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    width: 7.2rem;
  }
  .bottom2 {
    display: flex;
    flex-direction: column;
    align-items: center;

    font-family: "Helvetica Neue";
    font-size: 14pt;
    padding: 2rem;
    background: #e8dbc5;
    color: rgb(162, 122, 85);
    color: #403122;
    height: 21rem;
  }

  .rights-reserved {
    text-align: left;
    width: 100%;
    margin-top: 25px;
    margin-left: -1rem;
    font-size: 10pt;
  }

  .title {
    font-family: "itc-souvenir";
    font-size: 20pt;
    font-size: 27pt;
    /* font-size: 35pt; this looked pretty initial version */
    font-size: 45pt;
    color: #aa7950;
    color: white;
    width: 750px;
    margin-right: 2rem;
    margin-top: 1.2rem;
  }

  .contact-form {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
  }

  .contact-input {
    border: none;
    width: 25rem;
    background: none;
    height: 2rem;
    border: 1px solid #403122;
    margin: 1rem;
    background: #ede3d3;
    background: none;
    display: flex;
    flex-flow: row wrap;
    justify-content: flex-start;
    align-items: flex-start;
    text-align: start;
  }
  textarea {
    resize: none;
    font-family: system-ui, -apple-system, BlinkMacSystemFont, "Segoe UI",
      Roboto, Oxygen, Ubuntu, Cantarell, "Open Sans", "Helvetica Neue",
      sans-serif;
    font-size: 15px;
  }

  input[type="text"] {
    -webkit-box-sizing: border-box;
    -moz-box-sizing: border-box;
    box-sizing: border-box;
  }

  input[type="text"] {
    -webkit-box-sizing: border-box;
    -moz-box-sizing: border-box;
    box-sizing: border-box;
  }

  .contact-input:nth-child(3) {
    height: 7rem;
    margin-top: 0.5rem;
    width: 24.7rem;
  }

  .submit-form-button {
    background: #f7f6f0;
    background: #403122;
    height: 2rem;
    margin-left: 1rem;
    margin-top: 0.2rem;
    width: 6rem;
    color: rgb(76, 68, 58);
    color: white;
  }

  .about-text {
    color: #504d54;
    color: #181a1d;
    color: #595547;
    color: #353430;
    color: rgb(76, 68, 58);
    color: #e9e5dc;
    font-size: 17pt;
    line-height: 1.8;
  }

  .profile {
    width: 100%;
    height: 100%;
  }

  .content {
    display: flex;
    flex-direction: row;
    align-items: flex-start;
    margin-top: 0.6rem;
    margin-bottom: 0.6rem;
  }

  .title {
    font-family: "itc-souvenir";
    font-size: 20pt;
    font-size: 27pt;
    /* font-size: 35pt; this looked pretty initial version */
    font-size: 45pt;
    color: #aa7950;
    color: white;

    width: 750px;
    margin-right: 2rem;
    margin-top: 1.2rem;
  }

  .left {
    background: #f7f6f0;
    background: #f8f5ed;
    background: #f4f2ea;
    background: #fcf6eb;
    background: #f7f6f0;
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-between;

    /* justify-content: center; */
    /* border-right: 2px solid #eeeeee; */
    /* border-image: url(./assets/images/Background3.png) 200 round; */
  }

  .icons {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: flex-end;
    color: #b7b1a7;
    font-family: "Helvetica Neue";

    margin-right: 0.2rem;
    height: 1.5rem;
  }

  .icon {
    margin-right: 0.2rem;
    height: 1.2rem;
    width: 1.2rem;
  }

  .nav {
    display: flex;
    flex-direction: row;
    width: 100%;
    align-items: flex-start;
    justify-content: space-between;
    height: 10rem;
    margin-top: 0.5rem;
  }

  .left-footer {
    height: 5rem;
  }

  .sign-up-container {
    height: 40rem;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
  }

  .right {
    background: rgb(196, 195, 191);
    background: rgb(194, 193, 190);
    background: rgb(201, 198, 195);
    background: #b8b4b1;
    background: rgb(204, 203, 199);
    background: #beb7ac;
    background: #b6b3ae;
    background: #e9e5dc;
    background: #b7b1a7;
    background-image: url("./assets/images/Background-6.png");
    background-size: 200% 100%;
    width: 100%;
    height: 100vh;
    display: flex;
    flex-direction: row;
    align-items: flex-start;
    justify-content: center;
  }

  #background-video {
    height: 80%;
    width: 80%;
    position: absolute;
    margin: 5.3rem 0;
  }

  .logo {
    font-size: 260pt;
    font-size: 240pt;
    color: #aa7950;
    color: #aa7950;
    color: #a97a50;
    font-family: "hippie-mojo";
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
  }

  .slogan {
    font-family: "itc-souvenir";
    font-style: normal;
    font-weight: 500;
    font-size: 25px;
    font-size: 40px;
    font-size: 37px;
    color: #a7784f;
    color: #aa7950;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    margin-top: -0.5rem;
  }

  .sign-up {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    border: 1px solid #f8f7f4;
    border: none;
    padding: 2px 4px;
    border-radius: 20px;
    margin-top: 7rem;
    /* background: #e9e5dc;  */
    height: 2.7rem;
    height: 2.3rem;
    color: grey;
    width: 7rem;
    width: 12rem;
    font-size: 21px;
    font-size: 26px;
    font-size: 17px;
    font-size: 22px;
    display: flex;
    background: #ece5d4;
    background: #e9e5dc;
    margin-bottom: 5rem;
    padding: 0px 14px;
    padding: 0px 5px;
    font-weight: 300;
    height: 2.2rem;
  }

  .here-button {
    margin-left: 0.34rem;
    font-size: 25px;
    font-weight: 500;
  }

  .sign-up:hover {
    background: #d9d7d2;
    color: grey;
  }

  .suggestion-input {
    background: #fafafa;
    border: hidden;
    text-decoration: none;
    height: 2.2rem;
    outline: none;
    background: none;
    border-radius: 0 9px 9px 0;
    padding-left: 0.8rem;
    width: 20rem;
    font-family: system-ui, -apple-system, BlinkMacSystemFont, "Segoe UI",
      Roboto, Oxygen, Ubuntu, Cantarell, "Open Sans", "Helvetica Neue",
      sans-serif;
  }

  .suggestion-form {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    border: 1px solid #f8f7f4;
    border: none;
    padding: 2px 4px;
    border-radius: 20px;
    margin-top: 0.6rem;
    margin-top: 7rem;
    background: #f3eee2;
    margin-bottom: 5rem;
    background: #e9e5dc;
  }

  .suggestion-form-content {
    display: flex;
    flex-direction: row;
    align-items: center;
  }
}

@media screen and (min-width: 1000px) {
  .Home {
    display: flex;
    flex-direction: column;
  }

  .top {
    display: flex;
    flex-direction: row wrap;
  }

  .bottom {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: center;
    font-family: "Helvetica Neue";
    font-size: 14pt;
    padding: 2rem;
    background: #e5cc60;
    background: #ebebe4;
    background: #b7b1a7;
    background: #8a5016;
    background: #5a5551;
    background: #e9e5dc;
    background: #bcb8b5;
    background: #4e443c;

    font-size: 23px;
    color: rgb(162, 122, 85);
  }

  .bold {
    display: inline;
    font-weight: 600;
  }

  .icons {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: flex-end;
    color: #b7b1a7;
    font-family: "Helvetica Neue";

    margin-right: 0.2rem;
    height: 1.5rem;
  }

  .icon {
    margin-right: 0.5rem;
    height: 1.7rem;
    width: 1.7rem;
  }

  .menu > button {
    font-family: "Helvetica Neue";
    color: #b7b1a7;
    background: none;
    font-size: 16px;
  }

  .menu > button:hover {
    color: #403122;
  }

  .menu {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    width: 7.7rem;
    margin-left: 0.3rem;
    height: 1.5rem;
  }

  .menu > button:nth-child(2) {
    margin-left: -25px;
  }

  .bottom2 {
    display: flex;
    flex-direction: column;
    align-items: center;
    font-family: "Helvetica Neue";

    font-size: 14pt;
    padding: 2rem;
    background: #e5cc60;
    background: #ebebe4;
    background: #e9e5dc;
    background: #f4f0e5;
    background: #eeede7;
    font-size: 23px;
    background: #595547;
    background: #e9e5dc;
    background: #fefdf6;
    background: #eabc6c;
    background: rgb(76, 68, 58);
    background: #a17a56;
    background: #aa7950;
    background: #4e443c;
    background: #e9e5dc;
    background: url("./assets/images/aboutbackground.jpeg");
    background: #eabc6c;
    background: #e8dbc5;
    color: rgb(162, 122, 85);
    color: #403122;
  }

  .rights-reserved {
    text-align: left;
    width: 100%;
    margin-top: 30px;
    margin-left: -1rem;
    margin-bottom: -1.2rem;
    font-size: 11pt;
  }

  .title {
    font-family: "itc-souvenir";
    font-size: 20pt;
    font-size: 27pt;
    /* font-size: 35pt; this looked pretty initial version */
    font-size: 45pt;
    color: #aa7950;
    color: white;

    width: 750px;
    margin-right: 2rem;
    margin-top: 1.2rem;
  }

  .contact-form {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
  }

  .contact-input {
    border: none;
    width: 25rem;
    background: none;
    height: 2rem;
    border: 1px solid #403122;
    margin: 1rem;
    background: #f4eee4;
    background: #f7f6f0;
    background: #ede3d3;
    background: none;
    display: flex;
    flex-flow: row wrap;
    justify-content: flex-start;
    align-items: flex-start;
    text-align: start;
    font-size: 14px;
  }
  textarea {
    resize: none;
    font-family: system-ui, -apple-system, BlinkMacSystemFont, "Segoe UI",
      Roboto, Oxygen, Ubuntu, Cantarell, "Open Sans", "Helvetica Neue",
      sans-serif;

    padding-left: 0.3rem;
  }

  input[type="text"] {
    -webkit-box-sizing: border-box;
    -moz-box-sizing: border-box;
    box-sizing: border-box;
  }

  .contact-input:nth-child(3) {
    height: 7rem;
    margin-top: 0.5rem;
    width: 24.5rem;
  }

  .submit-form-button {
    background: #f7f6f0;
    background: #403122;
    height: 2rem;
    margin-left: 1rem;
    margin-top: 0.2rem;

    width: 6rem;
    color: rgb(76, 68, 58);
    color: white;
  }

  .about-text {
    color: #504d54;
    color: #181a1d;
    color: #595547;
    color: #353430;
    color: rgb(76, 68, 58);
    color: #e9e5dc;
    font-size: 17pt;
    line-height: 1.8;
  }

  .yingyang {
    width: 170px;
    height: 170px;
    margin-left: 2rem;
    margin-top: -1rem;
    -webkit-animation: spin 10s linear infinite;
    -moz-animation: spin 10s linear infinite;
    animation: spin 10s linear infinite;
  }

  @-moz-keyframes spin {
    100% {
      -moz-transform: rotate(360deg);
    }
  }
  @-webkit-keyframes spin {
    100% {
      -webkit-transform: rotate(360deg);
    }
  }
  @keyframes spin {
    100% {
      -webkit-transform: rotate(360deg);
      transform: rotate(360deg);
    }
  }

  .profile {
    width: 100%;
    height: 100%;
  }

  .content {
    display: flex;
    flex-direction: row;
    align-items: flex-start;
    margin-top: 0.6rem;
    margin-bottom: 0.6rem;
  }

  .title {
    font-family: "itc-souvenir";
    font-size: 20pt;
    font-size: 27pt;
    /* font-size: 35pt; this looked pretty initial version */
    font-size: 45pt;
    color: #aa7950;
    color: white;
    width: 750px;
    margin-right: 2rem;
    margin-top: 1.2rem;
  }

  .left {
    background: #f7f6f0;
    background: #f8f5ed;
    background: #f4f2ea;
    background: #fcf6eb;
    background: #f7f6f0;
    background: #f8f7f0;
    width: 50%;
    height: 100vh;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-between;
    /* justify-content: center; */
    /* border-right: 2px solid #eeeeee; */
    /* border-image: url(./assets/images/Background3.png) 200 round; */
  }

  .sign-up-container {
    height: 40rem;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
  }

  .nav {
    display: flex;
    flex-direction: row;
    width: 100%;
    align-items: flex-start;
    justify-content: space-between;
    height: 10rem;
    margin-top: 0.6rem;
  }
  .left-footer {
    height: 10rem;
  }

  .right {
    background: rgb(196, 195, 191);
    background: rgb(194, 193, 190);
    background: rgb(201, 198, 195);
    background: #b8b4b1;
    background: rgb(204, 203, 199);
    background: #beb7ac;
    background: #b6b3ae;

    background: #e9e5dc;
    background: #b7b1a7;

    background-image: url("./assets/images/Background10.png");
    background-size: cover;
    width: 50%;
    height: 100vh;
    display: flex;
    flex-direction: row;
    align-items: flex-start;
    justify-content: center;
  }

  #background-video {
    height: 85%;
    width: 50%;
    position: absolute;
    margin: 4rem 0;
  }

  .logo {
    /* margin-top: 200px; */
    /* font-size: 200pt;  looked pretty initially */
    font-size: 180pt;
    color: #a97a50;
    font-family: "hippie-mojo";
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
  }

  .slogan {
    font-family: "itc-souvenir";
    font-style: normal;
    font-weight: 500;
    font-size: 30px;
    font-size: 28px;
    font-size: 27px;
    color: #a7784f;
    color: #aa7950;
    color: #a7784f;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    margin-top: -0.5rem;
  }

  .sign-up {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    border: 1px solid #f8f7f4;
    border: none;
    padding: 2px 4px;
    border-radius: 20px;
    margin-top: 100px;
    /* background: #e9e5dc;  */
    height: 2.7rem;
    height: 2.3rem;
    color: grey;
    width: 7rem;
    width: 7.3rem;
    width: 9.5rem;
    font-size: 21px;
    font-size: 26px;
    font-size: 17px;
    display: flex;
    background: #ece5d4;
    background: #e9e5dc;
    padding: 0px 14px;
    padding: 0px 5px;
    font-weight: 300;
    height: 2.2rem;
  }

  .here-button {
    margin-left: 0.34rem;
    font-size: 25px;
    font-weight: 500;
  }

  .sign-up:hover {
    background: #d9d7d2;
    color: grey;
  }

  .suggestion-input {
    background: #fafafa;
    border: hidden;
    text-decoration: none;
    height: 2.2rem;
    outline: none;
    background: none;
    border-radius: 0 9px 9px 0;
    padding-left: 0.8rem;
    width: 18rem;
    font-family: system-ui, -apple-system, BlinkMacSystemFont, "Segoe UI",
      Roboto, Oxygen, Ubuntu, Cantarell, "Open Sans", "Helvetica Neue",
      sans-serif;
  }

  .suggestion-form {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    border: 1px solid #f8f7f4;
    border: none;
    padding: 2px 4px;
    border-radius: 20px;
    /* margin-top: 100px;  */
    margin-top: 95px;
    background: #f3eee2;
    background: #e9e5dc;
  }

  .suggestion-form-content {
    display: flex;
    flex-direction: row;
    align-items: center;
  }
  .note {
    text-align: end;
    color: rgb(169, 167, 167);
    font-size: 8pt;
    margin-top: 2px;
    margin-right: 10px;
    font-style: italic;
  }
}

.pause-btn {
  color: rgba(242, 242, 242, 0.7);
  color: black;
}

.display-icons {
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
  margin-top: 0.5rem;
}

.display-btn {
  color: #a97a50;
  color: #b7b1a7;
  margin-right: 1rem;
  width: 1.4rem;
  height: 1.4rem;
}

.btn-cover {
  width: 50%;
  height: 100vh;
  position: absolute;
  background: #434040;
  background: #817d7d;
  background: #817d7d;
  background: none;
  opacity: 30%;
  opacity: 80%;
  z-index: 900;
}

.pause {
  width: 50%;
  height: 100vh;
  position: absolute;
  z-index: 900;
  opacity: 4%;
  background: none;
}

button {
  border: none;
}

.pause-btn {
  color: rgba(242, 242, 242, 0.7);
}

input:-webkit-autofill,
input:-webkit-autofill:hover,
input:-webkit-autofill:focus,
input:-webkit-autofill:active {
  -webkit-transition-delay: 9999s;
  transition-delay: 9999s;
}

.submit {
  border: none;
  background: #e9e5dc;
  background: #e2ddd3;
  color: #272626;
  font-size: medium;
  padding: 0.6rem 1rem;
  border-radius: 3rem;
  align-self: flex-end;
  font-family: system-ui, -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto,
    Oxygen, Ubuntu, Cantarell, "Open Sans", "Helvetica Neue", sans-serif;
}

input {
  width: 250px;
  padding: 5px;
  -webkit-box-sizing: border-box; /* Safari/Chrome, other WebKit */
  -moz-box-sizing: border-box; /* Firefox, other Gecko */
  box-sizing: border-box; /* Opera/IE 8+ */
}

*:focus {
  outline: none;
}

.create-suggestion-button {
  border: none;
  background: none;
  color: white;
  color: #5c5848;
  border-radius: 50%;
  width: 2rem;
  height: 2rem;
  display: flex;
  flex-flow: row;
  align-items: center;
  justify-content: center;
  background: #d0c5b4;
  background: rgb(162, 122, 85);
  background: #c4bca6;
  background: none;
}

@font-face {
  font-family: "hippie-mojo";

  src: url("./assets/fonts/hippie-mojo/hippie-mojo.woff2") format("woff2"),
    /* Super Modern Browsers */
      url("./assets/fonts/hippie-mojo/hippie-mojo.woff") format("woff"),
    url("./assets/fonts/hippie-mojo/hippie-mojo.svg") format("svg");
  font-weight: 400;
  font-style: normal;
}

@font-face {
  font-family: "itc-souvenir";
  src: url("./assets/fonts/itc-souvenir/itc-souvenir.woff2") format("woff2"),
    /* Super Modern Browsers */
      url("./assets/fonts/itc-souvenir/itc-souvenir.woff") format("woff"),
    url("./assets/fonts/itc-souvenir/itc-souvenir.svg") format("svg");
  font-weight: 400;
  font-style: normal;
}
